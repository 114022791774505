<template>
  <section id="equipment-view">
    <b-row>
      <b-col cols="10">
        <Header title="Equipamentos" tooltip="Cadastre e gerencie equipamentos"></Header>
      </b-col>
      <b-col class='newButton' cols="2">
      <b-button variant="primary" @click="openModal">
        Novo equipamento
      </b-button>
      </b-col>
    </b-row>
    <section class="filters">
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <div class="flex-fill">
              <label for="search">Equipamento</label>
              <div class="input-container">
                <Search class="input-icon" />
                <b-form-input
                  type="search"
                  autocomplete="off"
                  id="procedure-search"
                  v-model="query"
                  :debounce="500"
                  placeholder="Buscar equipamento"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <label for="room">Sala</label>
            <multiselect
              :value="roomSelected"
              class="with-border"
              :options="rooms"
              :show-labels="false"
              label="name"
              id="room"
              @select="value => (room = value.id)"
              @search-change="getRoomsDebounce"
              placeholder="Selecionar"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Digite para iniciar a busca...
              </template>
              <template slot="noResult">Nenhuma sala encontrada</template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
    </section>

    <NoFilterResults v-if="!equipments.length" />

    <section class="table" v-else>
      <b-table id="equipaments-table" :items="equipments" :fields="fields">
        <template v-slot:cell(actions)="data">
          <div class="more-with-menu ml-auto">
            <MoreVertical class="more-icon" />
            <div class="menu">
              <b-button
                variant="link"
                @click="openModal({ equipment: data.item })"
              >
                Editar
              </b-button>
              <b-button
                variant="link remove mb-0"
                @click="confirmDeleteEquipment({ equipment: data.item })"
              >
                Remover
              </b-button>
            </div>
          </div>
        </template>

        <template v-slot:cell(room.name)="data">
          <span v-if="data.item?.appointment?.room?.name">{{ data.item.appointment.room.name }}</span>
          <span v-else-if="data.item?.room?.name">{{ data.item.room.name }}</span>
        </template>
      </b-table>

      <b-pagination
        v-if="equipments"
        class="float-right mt-1 mr-4 mb-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="equipaments-table"
      />
    </section>

    <EquipmentModal
      :equipment="equipment"
      @createdEquipment="createdEquipment"
      @updatedEquipment="updatedEquipment"
      @closeModal="() => (equipment = {})"
    />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import debounce from 'lodash/debounce'
export default {
  name: 'EquipmentsView',
  components: {
    Header: () => import('@/components/General/TabHeaderInfo'),
    EquipmentModal: () => import('@/components/Equipment/EquipmentModal'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    equipments: [],
    equipment: {},
    rooms: [],
    query: '',
    room: '',
    currentPage: 1,
    perPage: 10,
    rows: 0,
    getRoomsDebounce: Function,
    fields: [
      {
        key: 'name',
        label: 'Equipamento',
        thStyle: { width: '30%' }
      },
      {
        key: 'type',
        label: 'Tipo de equipamento',
        thStyle: { width: '20%' },
        thClass: 'text-left'
      },
      {
        key: 'room.name',
        label: 'Sala atual',
        thStyle: { width: '20%' },
        thClass: 'text-left'
      },
      {
        key: 'actions',
        label: '',
        thStyle: { width: '1%' },
        thClass: 'text-center'
      }
    ]
  }),
  computed: {
    roomSelected() {
      return this.rooms.find(room => room.id === this.room)
    }
  },
  async created() {
    this.getRoomsDebounce = debounce(this.getRooms, 500)
    await this.getEquipments()
  },
  methods: {
    async getEquipments() {
      const isLoading = this.$loading.show()
      try {
        this.equipments = []
        const { data } = await this.api.getEquipments(
          this.clinic.id,
          this.query,
          this.room,
          this.currentPage
        )
        this.equipments = data.data
  
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.rows = data.total
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    confirmDeleteEquipment({ equipment }) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir o <span>Equipamento ${equipment.name}</span>?
            Esta ação não poderá ser desfeita.
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deleteEquipment(equipment)
        }
      })
    },
    async deleteEquipment(equipment) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteEquipment(equipment.id)
        this.$toast.success('Equipamento removido com sucesso')
        this.updatedEquipment(equipment, true)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getRooms(query) {
      if (query.length < 2) return
      try {
        this.rooms = []
        const { data } = await this.api.getRoomsByName(this.clinic.id, query)
        this.rooms = data
        this.rooms.unshift({ id: '', name: 'Selecionar' })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    openModal({ equipment = {} }) {
      this.equipment = equipment
      this.$bvModal.show('equipment-modal')
    },
    createdEquipment(equipment) {
      this.equipments.push(equipment)
    },
    updatedEquipment(equipment, remove = false) {
      const index = this.equipments.findIndex(item => item.id === equipment.id)
      if (remove) {
        this.equipments.splice(index, 1)
        return
      }
      this.equipments.splice(index, 1, equipment)
    }
  },
  watch: {
    async query() {
      await this.getEquipments()
    },
    async room() {
      await this.getEquipments()
    },
    async currentPage() {
      await this.getEquipments()
    }
  }
}
</script>

<style lang="scss">
#equipment-view {
  .flex-fill {
    .input-container {
      position: relative;

      .input-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        margin-right: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
      }

      .form-control {
        padding: 11px 16px 11px 48px;
      }
    }
  }
  .newButton {
    padding: 20px 0 30px 0;
    justify-content: flex-end;
    display: flex;
  }
}
</style>
